<template>
  <div class="fleet">
    <div class="absolute top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="px-4 md:px-10 mx-auto w-full relative -mt-11">
      <HelpBar @getData="getData" :noDate="true">
        <template v-slot:right>
          <button
            class="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            @click="exportAsset"
          >
            <i class="fas fa-file-export" />
            {{ $t("export") }}
          </button>
        </template>
      </HelpBar>

      <div class="flex flex-wrap mt-4">
        <SlideUp>
          <ListTable
            :tableName="$t('asset')"
            :headers="headers"
            :tableData="$store.state.bikes.bikes"
            :loading="$store.state.bikes.loading"
            qrType="fleet"
            createName="Fleet-Detail"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";
import HelpBar from "../components/HelpBar.vue";
export default {
  name: "Fleet",
  components: {
    HelpBar,
    ListTable,
    SlideUp
  },
  data() {
    return {
      showQRs: false,
      headers: [
        {
          name: "#",
          key: "id",
          type: "hidden",
          linkInfo: { name: "Fleet-Detail", routeKey: "id", varKey: "id" }
        },
        {
          name: "name",
          key: "name",
          type: ""
        },
        {
          name: "status",
          key: "status",
          type: "label",
          noSort: true
        },
        {
          name: "type",
          key: "type.name",
          type: "",
          filter: true
        },
        {
          name: "unresolved issues",
          key: "unresolved_issues",
          notSearchable: true
        },
        { name: "hub", key: "hub.name", type: "", filter: true },
        { name: "Supplier", key: "supplier.name", type: "", filter: true },
        {
          name: "actions",
          key: "action",
          type: "action",
          noSort: true,
          actions: [
            {
              name: "clone",
              icon: "far fa-clone",
              action: (evt, bike) => {
                evt.stopPropagation();
                const confirmStr = `${this.$t("Confirm clone asset")}: ${
                  bike.name
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("bike/cloneBike", bike);
                });
              }
            },
            {
              name: "delete",
              icon: "far fa-trash-alt",
              action: (evt, bike) => {
                evt.stopPropagation();

                const confirmStr = `${this.$t("Confirm delete asset")}: ${
                  bike.name
                }`;
                this.$confirm(confirmStr).then(() => {
                  this.$store.dispatch("bike/deleteBike", {
                    bike
                  });
                });
              }
            }
          ]
        },
        // { name: "bike lock", key: "bike_lock.id", type: "" },
        // { name: "Total Shifts", key: "shift_count", type: "" },
        // { name: "Usage", key: "usages_in_min", type: "time" },
        { name: "notes", key: "notes", type: "" }
      ]
    };
  },
  methods: {
    getData() {
      this.$store.dispatch("bikes/getAllBikes");
    },
    exportAsset() {
      this.$store.dispatch("exportData/exportBikes", {
        ids: this.$store.state.bikes.bikes.map(b => b.id)
      });
    }
  },
  created() {
    this.$store.dispatch("hubs/getAllHubs", hubs => {
      this.$store.commit(
        "SET_HUBS",
        hubs.map(({ id }) => id)
      );
      this.getData();
    });
  }
};
</script>
